// @flow

import React, { useMemo, } from 'react';
import { useFela, } from 'react-fela';
import { parseComponentProp, parseStyleProps, } from '@haaretz/htz-css-tools';
import type { StyleProps, } from '@haaretz/htz-css-tools';

import { useInView, } from 'react-intersection-observer';
import { useUser, } from '../User/UserDispenser';
import useGetComponent from '../../hooks/GetComponentContext/useGetComponent';
import Tags from '../Tags/Tags';
import Caption from '../Caption/Caption';
import NoSSR from '../NoSSR/NoSSR';
import Tldr from '../Tldr/Tldr';
import Debug from '../Debug/Debug';
import type { Context, } from '../../flowTypes/articleContentType';
import Zen from '../Zen/Zen';
import TrinityPlayer from '../Scripts/TrinityPlayer';
import EventTracker, { useEventTracker, } from '../../utils/EventTracker';
import isOmnyCustomPlayer from '../../utils/isOmnyCustomPlayer';
import getArticleBodyComponentId from '../../utils/getArticleBodyComponentId';
import useWebViewChecker from '../../hooks/useWebViewChecker';
import ArticleBodyImage from '../ArticleBodyImage/ArticleBodyImage';
import useIsLabel from '../../hooks/Page/useIsLabel';
import useIsBlock from '../../hooks/useIsBlock';
import { flatBody, } from '../../hooks/Page/useArticleBodyData';
import checkSiteFromConfig from '../../utils/checkSiteFromConfig';
import usePrint from '../../hooks/Page/usePrint';
import { useExcludeResponsiveRender, useExcludeUserTypes, usePlatformRender, } from '../../utils/getElementsFactory';
import LinkElement from '../LinkElement/LinkElement';
import Outbrain from '../Outbrain/Outbrain';

import RainbowPaywallSlot from '../Marketing/RainbowPaywallSlot';

const { isHTZ, } = checkSiteFromConfig();


const mediaQueryCallback = (prop, value) => ({ [prop]: value, });

// eslint-disable-next-line react/prop-types
const Figure = ({ lastItem, children, }) => {
  const { css, theme, } = useFela();
  return (
    <figure
      className={css(
        !lastItem
          ? {
            ...parseComponentProp(
              'marginBottom',
              theme.articleStyle.body.marginBottom,
              theme.mq,
              mediaQueryCallback
            ),
          }
          : {}
      )}
    >
      {children}
    </figure>
  );
};

// eslint-disable-next-line react/prop-types
const Aside = ({ children, }) => {
  const { theme, css, } = useFela();
  return (
    <aside
      className={css({
        extend: [
          theme.mq(
            { from: 'l', },
            {
              position: 'absolute',
              textAlign: 'start',
              start: theme.layoutStyle.startColumnPadding,
            }
          ),
          theme.mq(
            { from: 'l', until: 'xl', },
            {
              start: theme.layoutStyle.startColumnPadding,
              width: '22rem',
            }
          ),
          theme.mq(
            { from: 'xl', },
            {
              start: theme.layoutStyle.startColumnPaddingXL,
              width: '26rem',
            }
          ),
          parseComponentProp(
            'marginBottom',
            theme.articleStyle.body.marginBottom,
            theme.mq,
            mediaQueryCallback
          ),
        ],
      })}
    >
      {children}
    </aside>
  );
};

export const buildImgOptions = (aspect, isFullScreen) => ({
  sizes: isFullScreen
    ? '100vw'
    : '(min-width:1280px) 592px,(min-width:1024px) 490px,(min-width:600px) 540px, calc(100vw - 36px)',
  transforms: [
    {
      width: '350',
      aspect,
      quality: 'auto',
    },
    {
      width: '490',
      aspect,
      quality: 'auto',
    },
    {
      width: '600',
      aspect,
      quality: 'auto',
    },

    {
      width: '700',
      aspect,
      quality: 'auto',
    },
    {
      width: '1024',
      aspect,
      quality: 'auto',
    },
    {
      width: '1200',
      aspect,
      quality: 'auto',
    },
  ],
});

type BuildComponentProps = {
  context: Context,
  site: ?('htz' | 'tm' | 'hdc'),
  index: number,
  isLastItem: boolean,
  showNewsletter: boolean,
};

BuildComponent.defaultProps = {
  context: null,
  site: null,
  index: 0,
  isLastItem: false,
  showNewsletter: true,
};

function BuildComponent({
  context,
  site,
  index,
  isLastItem,
  showNewsletter,
}: BuildComponentProps) {
  const { theme, } = useFela();
  const isLabel = useIsLabel();
  const { biAction, biImpression, } = useEventTracker();
  const biActionData = context?.includeData ? {
    actionCode: context?.actionId,
    campaignDetails: context?.campaignDetails,
    campaignName: context?.campaignName,
    featureType: context?.featureType,
    feature: context?.feature,
  } : null;

  const biImpressionData = context?.includeData ? {
    campaignDetails: context?.campaignDetails,
    campaignName: context?.campaignName,
    featureType: context?.featureType,
    feature: context?.feature,
  } : null;

  const observerSettings = { threshold: 0.5, triggerOnce: true, };
  const [ref, inView,] = useInView(observerSettings);

  const getComponent = useGetComponent();

  const appearByMQ = useExcludeResponsiveRender(context?.preventRender);
  const appearByPlatform = usePlatformRender(context?.excludePlatform);
  const appearByUserType = useExcludeUserTypes(context?.excludeUserTypes);

  if (!context) {
    return (
      <Debug>
        no context, index is
        {index}
      </Debug>
    );
  }

  if (!(appearByMQ && appearByPlatform && appearByUserType)) return null;

  const uniqueId = getArticleBodyComponentId(context);
  const Component = getComponent(uniqueId, context);

  switch (uniqueId) {
    case 'Embed':
    case 'EmbedRichTextElement':
      return (
        <Figure lastItem={isLastItem}>
          <Component {...context} />
        </Figure>
      );
    // case 'com.tm.ImageGalleryElement':
    case 'GalleryRichTextElement':
      return (
        <Figure lastItem={isLastItem}>
          <Component
            {...context}
            imgOptions={(aspect, isFullScreen) => buildImgOptions(aspect, isFullScreen)}
          />
          {context.title || context.caption || context.credit ? (
            <Caption caption={context.title || context.caption} credit={context.credit} />
          ) : null}
        </Figure>
      );
    case 'interactive':
    case 'InteractiveRichTextElement':
    case 'Video': // eslint-disable-line no-case-declarations
      return (
        <Figure lastItem={isLastItem}>
          <Component {...context} />
          {context.title || context.caption || context.credit ? (
            <Caption caption={context.title || context.caption} credit={context.credit} />
          ) : null}
        </Figure>
      );
    case 'com.polobase.promotedContentElement':
      return (
        <Component
          {...context}
          miscStyles={
            isLastItem
              ? null
              : parseComponentProp(
                'marginBottom',
                theme.articleStyle.body.marginBottom,
                theme.mq,
                mediaQueryCallback
              )
          }
          {...(uniqueId === 'HtmlNode' && (context.tag === 'p' || context.tag === 'a' || context.tag === 'h4')
            ? {
              renderFirstImpression: !isLastItem,
            }
            : {})}
        />
      );
    case 'QuoteRichTextElement':
      return (
        <Aside>
          <Component {...context} />
        </Aside>
      );
    case 'DfpBanner':
    case 'DfpBannerRichTextElement':
      return <Component {...context} {...context.properties} />;
    case 'Outbrain':
    case 'OutbrainRichTextElement':
      return (
        <NoSSR>
          <Outbrain
            {...context}
            {...context?.properties}
          />
        </NoSSR>
      );
    case 'RegistrationRichTextElement':
      if (showNewsletter) {
        return (
          <NoSSR>
            <Zen animate>
              <Component
                {...context}
                {...context.properties}
                miscStyles={{ marginTop: '4rem', marginBottom: '4rem', }}
              />
            </Zen>
          </NoSSR>
        );
      }
      break;
    case 'OmnyStudio':
    case 'com.polobase.audioEmbed':
    case 'articleNarration':
      return (
        <Component
          fileUrl={context.fileUrl || (context.settings || {}).fileUrl}
          contentName={context.contentName}
          caption={context.caption}
          channelLinks={(context.settings || {}).channelLinks}
          isOmny={isOmnyCustomPlayer(context)}
          loadAfterFirstClick={isOmnyCustomPlayer(context)}
          channelName={(context.settings || {}).channelName}
          channelLabel={(context.settings || {}).channelLabel}
          image={context.image}
          mobileTitle={context.mobileTitle}
          miscStyles={
            isLastItem
              ? null
              : { marginBottom: theme.articleStyle.body.marginBottom, }
          }
        />
      );
    case 'image':
    case 'infographic':
    case 'htz_image_Image':
      return (context?.includeData
        ? (
          <ArticleBodyImage
            includeData
            biAction={() => (biActionData ? biAction(biActionData) : undefined)}
            biImpression={() => (biImpressionData ? biImpression(biImpressionData) : undefined)}
            wrapRef={ref}
            inView={inView}
            lastItem={isLastItem}
            {...context}
            imgOptions={(aspect, isFullScreen) => buildImgOptions(aspect, isFullScreen)}
          />
        )
        : (
          <ArticleBodyImage
            lastItem={isLastItem}
            {...context}
            imgOptions={(aspect, isFullScreen) => buildImgOptions(aspect, isFullScreen)}
          />
        )
      );
    case 'VisualInfo':
    case 'QAndA':
    case 'ColumnsModel':
    case 'Timeline':
    case 'Ids':
    case 'ListInfo':
    case 'Chat':
      return (
        <Component
          {...context}
          isLabel={isLabel}
          miscStyles={{
            ...(isLastItem
              ? null
              : {
                marginBottom:
                  [
                    { until: 's', value: '4rem', },
                    { from: 's', value: '5rem', },
                  ],
                marginTop:
                  [
                    { until: 's', value: '4rem', },
                    { from: 's', value: '5rem', },
                  ],
              }
            ),
            ...(['relatedArticles', 'relatedArticleSeries',].includes(uniqueId)
              ? { marginTop: 0, }
              : {}),
          }}
          {...(uniqueId === 'HtmlNode' && (context.tag === 'p' || context.tag === 'a' || context.tag === 'h4')
            ? {
              renderFirstImpression: !isLastItem,
            }
            : {})}
        />
      );
    case 'htz_link_Link':
      return (
        <LinkElement
          {...context}
          site={site}
          miscStyles={{
            ...(isLastItem
              ? null
              : parseComponentProp(
                'marginBottom',
                theme.articleStyle.body.marginBottom,
                theme.mq,
                mediaQueryCallback
              )),
          }}
          includeData={context?.includeData}
          biAction={() => (biActionData ? biAction(biActionData) : undefined)}
          biImpression={() => (biImpressionData ? biImpression(biImpressionData) : undefined)}
          wrapRef={ref}
          inView={inView}
        />
      );
    default:
      return (
        <Component
          {...context}
          isLabel={isLabel}
          site={site}
          miscStyles={{
            ...(isLastItem
              ? null
              : parseComponentProp(
                'marginBottom',
                theme.articleStyle.body.marginBottom,
                theme.mq,
                mediaQueryCallback
              )),
            ...(['relatedArticles', 'relatedArticleSeries',].includes(uniqueId)
              ? { marginTop: 0, }
              : {}),
          }}
          {...(uniqueId === 'HtmlNode' && (context.tag === 'p' || context.tag === 'a' || context.tag === 'h4')
            ? {
              renderFirstImpression: !isLastItem,
            }
            : {})}
        />
      );
  }
  return null;
}
const wrapperStyle = ({ miscStyles, theme, isPrintByArticleType, }) => ({
  maxWidth: isPrintByArticleType ? theme.articleStyle.body.maxWidth : undefined,
  marginRight: 'auto',
  marginLeft: 'auto',
  extend: [...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),],
});

type Props = {
  /**
   * The elements composing the article’s body.
   */
  // body: PropTypes.arrayOf(PropTypes.oneOfType([ PropTypes.string, PropTypes.object, ])).isRequired,
  body: Array<Context>,
  /**
   * Display newsletter in article body.
   */
  showNewsletter: boolean,

  /**
   * Display paywall in article body
   */
  renderPaywall: boolean,

  /**
   * A special property holding miscellaneous CSS values that
   * trumps all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  miscStyles?: ?StyleProps,
  site: ?('htz' | 'tm' | 'hdc'),
  showTrinityPlayer: boolean,
  showTldr?: boolean,
  showTags?: boolean,
  flatted?: boolean,
};

export function clearBody(props: {
  body: Array<Context>,
  isWebView: boolean,
}) {
  const { body, isWebView, } = props || {};

  let result = (body || []);

  if (isWebView) {
    const adExceptions = ['.textlink',];

    if (isHTZ) {
      adExceptions.push('.inread.');
    }
    else {
      adExceptions.push('inread.2', 'inread.3');
    }

    // $FlowFixMe
    result = result.filter((item: Context) => adExceptions.every(ad => !(item.contentName || '').toLowerCase().includes(ad)));
  }

  return result;
}

ArticleBody.defaultProps = {
  showNewsletter: true,
  miscStyles: null,
  renderPaywall: true,
  site: null,
  showTrinityPlayer: false,
  showTldr: true,
  showTags: true,
  flatted: true,
};

function ArticleBody({
  miscStyles,
  showNewsletter,
  renderPaywall,
  site,
  showTrinityPlayer,
  showTldr,
  showTags,
  flatted,
  ...props
}: Props) {
  const { isPrintByArticleType, } = usePrint();
  const { css, theme, } = useFela({ miscStyles, isPrintByArticleType, });
  const { user, } = useUser();
  const isWebView = useWebViewChecker();
  const isLabel = useIsLabel();
  const isBlock = useIsBlock();

  const body = useMemo(() => {
    const result = clearBody({ body: props.body, isWebView, });

    if (flatted) {
      return result;
    }

    return flatBody(result);
  }, [flatted, isWebView, props.body,]);

  const hasBody = body && body.length > 0;
  return (
    <div className={css(wrapperStyle)} data-test="articleBody">
      {showTrinityPlayer && !isBlock && (
        <EventTracker>
          {({ biImpression, biAction, }) => (
            <TrinityPlayer biImpression={biImpression} biAction={biAction} user={user} />
          )}
        </EventTracker>
      )}
      {showTldr && theme.articleStyle.body.displayTldr
        ? <Tldr />
        : null}
      {hasBody
        && body.map((component, i) => {
          if (!component) {
            return null;
          }

          const key = component
            ? component.contentId
            || `${component.kind || component.inputTemplate || component.tag || 'empty'}-${i}`
            : `empty-${i}`;

          const isLastItem = i === body.length - 1;

          if (component.view === 'Spot' && isLastItem) {
            return null;
          }

          return (
            <BuildComponent
              key={key}
              site={site}
              context={component}
              index={i}
              isLastItem={isLastItem}
              showNewsletter={showNewsletter}
              isLabel={isLabel}
            />
          );
        })}
      {renderPaywall && <RainbowPaywallSlot id="mid-page" shouldHideContent={hasBody} />}
      {!isBlock && showTags && hasBody ? <Tags /> : null}
    </div>
  );
}

export default ArticleBody;
